var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{on:{"click":_vm.openModal}},[_vm._t("default")],2),_c('b-modal',{ref:"modal",attrs:{"id":"modal","cancel-variant":"danger","ok-title":_vm.$t('Save'),"cancel-title":_vm.$t('Delete'),"centered":"","title":_vm.productMenuSettingItem ? 'Chỉnh sửa' : 'Thêm mới',"no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.submit($event)},"cancel":function($event){$event.preventDefault();return _vm.remove($event)}}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'category',"text":_vm.$t('Category'),"required":true}}),_c('validation-provider',{attrs:{"name":"category","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('v-select',{attrs:{"id":"category","placeholder":_vm.$t('categoryPlaceHolder'),"options":_vm.productCategories,"state":errors.length > 0 ? false : null,"label":"name","reduce":function (cate) { return cate.id; }},on:{"input":_vm.onCategoryChange,"close":function () {
                  validate();
                }},model:{value:(_vm.input.categoryId),callback:function ($$v) {_vm.$set(_vm.input, "categoryId", $$v)},expression:"input.categoryId"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'category',"text":_vm.$t('Service')}}),_c('validation-provider',{attrs:{"name":"service","rules":"","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"service","label":"name","placeholder":_vm.$t('servicePlaceHolder'),"state":errors.length > 0 ? false : null,"options":_vm.products,"reduce":function (product) { return product.id; }},model:{value:(_vm.input.productId),callback:function ($$v) {_vm.$set(_vm.input, "productId", $$v)},expression:"input.productId"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'menuType',"text":"Loại danh mục","required":true}}),_c('validation-provider',{attrs:{"name":"menuType","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var validate = ref.validate;
return [_c('v-select',{attrs:{"id":"menuType","placeholder":"Sản phẩm in nhanh...","options":_vm.productMenuTypes,"state":errors.length > 0 ? false : null,"label":"text","reduce":function (cate) { return cate.value; }},on:{"close":function () {
                  validate();
                }},model:{value:(_vm.input.productMenuTypeId),callback:function ($$v) {_vm.$set(_vm.input, "productMenuTypeId", $$v)},expression:"input.productMenuTypeId"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'url',"text":'Url'}}),_c('validation-provider',{attrs:{"name":"url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false : null,"placeholder":"Url"},model:{value:(_vm.input.url),callback:function ($$v) {_vm.$set(_vm.input, "url", $$v)},expression:"input.url"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'STT',"text":'Số thứ tự',"required":true}}),_c('validation-provider',{attrs:{"name":"stt","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"stt","state":errors.length > 0 ? false : null,"placeholder":"0","type":"number"},model:{value:(_vm.input.ordinal),callback:function ($$v) {_vm.$set(_vm.input, "ordinal", $$v)},expression:"input.ordinal"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'image',"text":'Hình ảnh',"required":!_vm.productMenuSettingItem}}),_c('validation-provider',{attrs:{"name":"image","rules":!_vm.productMenuSettingItem ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var failedRules = ref.failedRules;
return [_c('b-form-file',{attrs:{"value":_vm.input.image,"state":errors.length > 0 ? false : null,"name":"Image","accept":".jpg, .jpeg, .png"},on:{"change":_vm.imageChange}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])}),(_vm.input.image && _vm.isReplaceImage)?_c('b-img',{staticClass:"mt-1 w-100",attrs:{"center":"","rounded":"","src":_vm.input.image
                ? _vm.URL.createObjectURL(_vm.input.image)
                : (_vm.VUE_APP_API_BASE_HOST + "/" + (_vm.productCategory.image))}}):_vm._e(),(_vm.input.id && !_vm.isReplaceImage)?_c('b-img',{staticClass:"form-img",attrs:{"center":"","rounded":"","src":_vm.editImgPath}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }