import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption,
  BFormCheckbox,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  ProductCategoryViewModel,
  FileParameter,
  ProductCategoryStatus,
  ProductCategoryOrderNumber,
  UpdateCategoryOrderNumbers,
  ProductViewModel,
  MasterDataViewModel,
  ProductMenuSettingItem
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import {
  ProductCategoryAction,
  ProductCategoryGetter,
  productCategoryNamespace,
  ProductCategoryState,
  PRODUCT_CATEGORY_STATE_NAMESPACE
} from '@/store/productCategory/productCategory.module-types';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import draggable from 'vuedraggable';
import { mapActions, mapState } from 'vuex';
import {
  ProductAction,
  ProductState,
  PRODUCT_STATE_NAMESPACE
} from '@/store/product/product.module-types';
import vSelect from 'vue-select';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption,
    BFormCheckbox,
    draggable,
    BListGroup,
    BListGroupItem,
    vSelect
  },
  computed: {
    ...mapState(PRODUCT_STATE_NAMESPACE, [ProductState.products]),
    ...mapState(PRODUCT_CATEGORY_STATE_NAMESPACE, [
      ProductCategoryState.productCategories
    ])
  },
  methods: {
    ...mapActions(PRODUCT_CATEGORY_STATE_NAMESPACE, [
      ProductCategoryAction.fetchProductCategories
    ]),
    ...mapActions(PRODUCT_STATE_NAMESPACE, [ProductAction.fetchProducts])
  }
})
export default class ProductMenuSettingFormModal extends Vue {
  @Prop({ type: Array, required: true })
  productMenuTypes!: DropdownOption[];

  @Prop({ type: ProductMenuSettingItem, required: false })
  productMenuSettingItem: ProductMenuSettingItem | null | undefined;
  @Prop({ type: Number, required: false })
  editproductMenuSettingType: number | null | undefined;

  input = this.getDefaultInputValue();
  orderingListLoading = false;
  editImgPath = '';
  isReplaceImage = false;

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  productCategoryStatusOptions: DropdownOption[] = enumToDropdownOptions(
    ProductCategoryStatus
  );

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
    oderingModal: InstanceType<typeof BModal>;
  };

  orderingList: ProductCategoryOrderNumber[] = [];
  productFiltered: ProductViewModel[] = [];

  //Vuex
  products!: ProductViewModel[];
  fetchProducts!: () => Promise<void>;
  productCategories!: ProductCategoryViewModel[];
  fetchProductCategories!: () => Promise<void>;
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  mounted() {
    this.loadData();
  }

  loadData() {
    if (!this.products || this.products.length === 0) this.fetchProducts();
    if (!this.productCategories || this.productCategories.length === 0)
      this.fetchProductCategories();
  }

  openModal(): void {
    this.input = this.getDefaultInputValue();
    if (this.productMenuSettingItem) {
      this.input.categoryId = this.productMenuSettingItem.categoryId;
      this.input.id = this.productMenuSettingItem.id;
      this.editImgPath = `${this.VUE_APP_API_BASE_HOST}/${this.productMenuSettingItem.imagePath}`;
      this.input.ordinal = this.productMenuSettingItem.ordinal;
      this.input.productId = this.productMenuSettingItem.productId;
      this.input.productMenuTypeId = this.editproductMenuSettingType ?? 1;
      this.input.url = '';
      this.isReplaceImage = false;
      //this.input.image = this.productMenuSettingItem.imagePath ?? '';
    }
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      id: '',
      image: null,
      url: '',
      productId: 0,
      categoryId: 0,
      ordinal: 0,
      productMenuTypeId: 0
    };
  }

  onCategoryChange() {
    this.input.productId = 0;
    this.productFiltered = this.products
      .filter((val) => val.productCategoryId === this.input.categoryId)
      .sort((a, b) => (a?.displayNumber ?? 0) - (b?.displayNumber ?? 0));
  }

  add(): void {
    const client = new ApiClientFactory().productClient();
    const imageFile: FileParameter = {
      data: this.input.image,
      fileName: (this.input.image as any).name
    };

    client
      .createProductMenuSetting(
        null,
        this.input.productId !== 0 ? this.input.productId : null,
        this.input.categoryId,
        this.input.ordinal,
        imageFile,
        this.input.productMenuTypeId,
        false,
        this.input.url
      )
      .then((result) => {
        if (result) {
          //productCategoryListDispatcher.load();
          this.$emit('onSubmitSuccess');
          this.$refs.modal.hide();
          // show toast
          this.$bvToast.toast('Thêm mới menu danh mục sản phẩm thành công', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
        } else {
          //productCategoryListDispatcher.load();
          // show toast
          this.$bvToast.toast('menu danh mục sản phẩm đã tồn tại!!!', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      })
      .catch((err) => {
        //productCategoryListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Thêm menu danh mục sản phẩm thất bại', {
          title: 'Danh mục sản phẩm',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });
  }

  edit() {
    const client = new ApiClientFactory().productClient();
    const imageFile: FileParameter | null =
      this.input.image && this.isReplaceImage
        ? {
            data: this.input.image,
            fileName: (this.input.image as any).name
          }
        : null;

    client
      .updateProductMenuSetting(
        this.input.id,
        this.input.productId !== 0 ? this.input.productId : null,
        this.input.categoryId,
        this.input.ordinal,
        imageFile,
        this.input.productMenuTypeId,
        false,
        this.input.url
      )
      .then((result) => {
        if (result) {
          this.$emit('onSubmitSuccess');
          this.$refs.modal.hide();
          // show toast
          this.$bvToast.toast('Chỉnh sửa danh mục sản phẩm thành công', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('Chỉnh sửa danh mục sản phẩm thất bại', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      });
  }

  remove() {
    const client = new ApiClientFactory().productClient();
    client
      .removeProductMenuSetting(
        this.input.id,
        this.input.productId !== 0 ? this.input.productId : null,
        this.input.categoryId,
        this.input.ordinal,
        null,
        this.input.productMenuTypeId,
        false,
        this.input.url
      )
      .then((result) => {
        if (result) {
          this.$emit('onSubmitSuccess');
          this.$refs.modal.hide();
          // show toast
          this.$bvToast.toast('Xóa danh mục sản phẩm thành công', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('Xóa danh mục sản phẩm thất bại', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      });
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.image = event.target.files[0];
      this.isReplaceImage = true;
    }
  }
  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.productMenuSettingItem && this.input.id) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }
}
