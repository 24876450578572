import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { Vue, Component } from 'vue-property-decorator';
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from 'bootstrap-vue';
import ProductMenuSettingContent from './ProductMenuSettingContent.vue';
import { ProductMenuSettingModel } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import ProductMenuSettingFormModal from './productMenuSettingForm/ProductMenuSettingFormModal.vue';

@Component({
  components: {
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    ProductMenuSettingContent,
    ProductMenuSettingFormModal
  }
})
export default class ProductMenuSettingTabs extends Vue {
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;

  isLoading = false;
  productMenuSettings: ProductMenuSettingModel[] = [];
  productMenuTypes: DropdownOption[] = [];

  $refs!: {
    productMenuSettingFormModalRef: InstanceType<
      typeof ProductMenuSettingFormModal
    >;
  };

  mounted() {
    this.reloadList();
  }

  reloadList() {
    this.isLoading = true;
    const client = new ApiClientFactory().productClient();
    client
      .getProductMenuSetting()
      .then((result) => {
        this.productMenuSettings = result;
        this.productMenuTypes = result.map((s) => {
          return {
            value: s.productMenuTypeId,
            text: this.$t(s.productMenuTypeName ?? '')
          } as DropdownOption;
        });
      })
      .catch((err) => {
        // show toast
        this.$bvToast.toast('Tải dữ liệu thất bại', {
          title: 'Menu danh mục sản phẩm',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
