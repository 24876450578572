



















































































































































import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import {
  ProductMenuSettingItem,
  ProductMenuSettingModel,
  ProductMenuTypeInput
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import {
  ProductAction,
  ProductState,
  PRODUCT_STATE_NAMESPACE
} from '@/store/product/product.module-types';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  BTable,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BThead,
  BTbody,
  BFormInput,
  BButton,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BContainer,
  BRow,
  BCol,
  BDropdownDivider,
  BOverlay,
  BSpinner,
  BCardText,
  BCard,
  BModal,
  BFormGroup,
  BForm
} from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import ProductMenuSettingFormModal from './productMenuSettingForm/ProductMenuSettingFormModal.vue';

// Define the component in class-style
@Component({
  components: {
    BTable,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BThead,
    BTbody,
    BFormInput,
    BButton,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BContainer,
    BRow,
    BCol,
    BDropdownDivider,
    BOverlay,
    BSpinner,
    BCardText,
    BCard,
    BModal,
    ProductMenuSettingFormModal,
    CustomLabel,
    BFormGroup,
    BForm
  },
  computed: { ...mapState(PRODUCT_STATE_NAMESPACE, [ProductState.products]) },
  methods: {
    ...mapActions(PRODUCT_STATE_NAMESPACE, [ProductAction.fetchProducts])
  }
})
export default class ProductMenuSettingContent extends Vue {
  @Prop({ type: ProductMenuSettingModel, required: true })
  productMenuSettingModel!: ProductMenuSettingModel;
  @Prop({ type: Array, required: true })
  productMenuTypes!: DropdownOption[];

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  COLOR_MODAL = `modal-color-${this.productMenuSettingModel.productMenuTypeId}`;
  selectedProductMenuTypeId = 0;
  productMenuTypeInput: {
    id: number;
    themeColor: string;
    name: string;
  } = {
    id: this.productMenuSettingModel.productMenuTypeId,
    themeColor: this.productMenuSettingModel.themeColor ?? '',
    name: this.productMenuSettingModel.productMenuTypeName ?? ''
  };

  onSubmitSuccess() {
    this.$emit('onSubmitSuccess');
  }

  openColorModal() {
    this.$bvModal.show(this.COLOR_MODAL);
  }

  onColorModalSubmit() {
    const client = new ApiClientFactory().productClient();
    client
      .updateProductMenuType({
        ...this.productMenuTypeInput
      } as ProductMenuTypeInput)
      .then((result) => {
        if (result) {
          this.$emit('onSubmitSuccess');
          this.$bvModal.hide(this.COLOR_MODAL);
          // show toast
          this.$bvToast.toast('Chỉnh sửa loại danh mục sản phẩm thành công', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('Chỉnh sửa loại danh mục sản phẩm thất bại', {
            title: 'Danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      });
  }

  get productMenuSettingItemsPaged() {
    const result: any[] = [];
    if (this.productMenuSettingModel.productMenuSettingItems) {
      const totalPage =
        this.productMenuSettingModel.productMenuSettingItems.length / 3;
      if (totalPage > 0) {
        for (let i = 0; totalPage >= i; i = i + 1) {
          var isLastPage = totalPage - i < 1;
          result.push({
            page: i,
            items: isLastPage
              ? this.productMenuSettingModel.productMenuSettingItems.slice(
                  i * 3
                )
              : this.productMenuSettingModel.productMenuSettingItems.slice(
                  i * 3,
                  3
                )
          });
        }
      }
    }

    return result;
  }
}
